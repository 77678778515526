<template>
  <div class="wrap">
    <p class="title">{{ tagType === 1 ? '直播' : '视频' }}标签</p>
    <!-- 操作 -->
    <div class="action">
      <a-button size="small" @click="returnBack">返回</a-button>
      <a-button type="primary" size="small" @click="showAddEditModal">新增标签</a-button>
    </div>
    <!-- 表格 -->
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{ pageSize: pageSize, current: curPage, total: total }"
      :row-key="record => record.id"
      @change="handlePageChange"
    >
      <template #action="row">
        <div class="action">
          <span class="edit" @click="showAddEditModal(row)">编辑</span>
          <span class="del" @click="del(row)">删除</span>
        </div>
      </template>
    </a-table>
  </div>
  <add-edit-tag-modal ref="AddEditTagRef" @addOrUpdateSuccess="search"></add-edit-tag-modal>
</template>

<script>
import { defineComponent, ref, toRefs, createVNode, reactive, onMounted } from 'vue';
import api from '@/services';
import { Modal, message } from 'ant-design-vue';
import AddEditTagModal from './AddEditTagModal';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
    ellipsis: true
  },
  {
    title: '名称',
    dataIndex: 'labelName',
    align: 'center'
  },
  {
    title: '描述',
    dataIndex: 'remark',
    align: 'center'
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 150,
    align: 'center',
    slots: {
      customRender: 'action'
    }
  }
];
export default defineComponent({
  props: {
    tagType: {
      type: [String, Number]
    },
    showTagMange: {
      type: Boolean
    }
  },
  components: {
    AddEditTagModal
  },
  emits: ['returnBack'],
  setup(props, context) {
    onMounted(() => {
      getTagList();
    });

    const AddEditTagRef = ref();
    const isEdit = ref(false);

    const visible = ref(false);

    // table 相关变量
    const tableState = reactive({
      pageSize: 5,
      curPage: 1,
      total: 0,
      dataSource: [],
      columns
    });

    // 获取预警列表 table data
    const getTagList = async () => {
      const params = {
        type: String(props.tagType),
        curPage: tableState.curPage,
        pageSize: tableState.pageSize
      };

      const { data, success } = await api.getTagList(params);
      if (success) {
        tableState.dataSource = data.records;
        tableState.total = data.total;
      }
    };

    // 显示 新增 / 编辑 弹窗
    const showAddEditModal = row => {
      let rowData;
      if (row.record) {
        isEdit.value = true;
        rowData = row.record;
      } else {
        isEdit.value = false;
      }
      AddEditTagRef.value.showModal(isEdit.value, rowData, props.tagType);
    };

    // 删除
    const del = row => {
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;'
          },
          `确认要删除该标签吗？`
        ),

        async onOk() {
          let { success, msg } = await api.delTagById(row.record.id);
          if (success) {
            message.success('删除成功！');
            search();
          } else {
            message.error(msg);
          }
        }
      });
    };

    const handleCancel = () => {
      visible.value = false;
    };

    const handlePageChange = page => {
      tableState.curPage = page.current;
      getTagList();
    };

    const search = () => {
      tableState.curPage = 1;
      getTagList();
    };

    const returnBack = () => {
      context.emit('returnBack');
    };

    return {
      AddEditTagRef,
      showAddEditModal,
      isEdit,
      visible,
      getTagList,
      handlePageChange,
      search,
      del,
      handleCancel,
      returnBack,
      ...toRefs(tableState)
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .action {
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      margin-right: 20px;
    }
  }
  :deep(.ant-table-wrapper) {
    margin-top: 15px;
    .ant-table {
      .action > span {
        margin-right: 14px;
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }

      .action {
        display: flex;
        span {
          cursor: pointer;
          color: #fff;
        }
        .edit {
          background-color: #007fff;
        }
        .del {
          background-color: #d71345;
        }
      }
    }
  }
}
</style>
